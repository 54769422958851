.BackGround {
    display: flex;
    background-color: rgb(238, 238, 238);
    width: 85%;
    margin: 111px auto;
    padding: 20px;
    border-radius: 5px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 20px; */
    box-sizing: border-box;
}
.flatTitle {
    /* position: absolute;
    color: white;
    padding-top: 20px;
    padding-left: 20px;
    font-size: medium;
    font-weight: bold; */
    position: absolute;
    color: white;
    padding-top: 20px;
    padding-left: 20px;
    font-size: larger;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.flat {
    border-radius: 5px;
    position: relative;
    height: 327px;
    width: 327px;
    box-sizing: border-box;
    margin: 20px;
    overflow: hidden;
}

.flat img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 539px) {
    .BackGround {
        background-color: white;
        width: 100%;
        margin: 40px auto;
        padding: 10px;
        row-gap: 10px;
        column-gap: 10px;
        justify-content: center;
        gap: 10px;
    }

    .flat {
        margin: 10px;
    }
}

@media (max-width: 539px) {
    .BackGround {
        margin: 20px auto;
        padding: 10px;
        row-gap: 10px;
        column-gap: 10px;
        gap: 10px;
        margin-top: -50px;
    }

    .flat {
        width: 100%;
    }

    .flat img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0 auto;
    }
}
.newFicheContainer {
    border-radius: 10px;
}
