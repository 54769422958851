.mcrBanner {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 140px;
    margin-top: 54px;
    margin-bottom: 400px;
}

.mcrBanner h1 {
    position: absolute;
    top: 59px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    color: #fff;
    font-size: 28px;
    text-align: center;
    width: 100%;
    white-space: nowrap;
}

.mcrBanner img {
    width: 85%;
    height: 500px;
    object-fit: cover;
    border-radius: 5px;
    margin: 40px auto;
    margin-top: 357px;
    z-index: 1;
}

@media (max-width: 539px) {
    .mcrBanner {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 100%;
        height: 140px;
        margin-top: 54px;
        margin-bottom: 0px;
    }
    .mcrBanner h1 {
        text-align: left;
        width: 70%;
        white-space: pre-wrap;
        word-break: break-word;
        margin-top: -80px;
    }

    .mcrBanner img {
        height: 122px;
        margin-bottom: 463px;
        width: calc(100% - 40px);
    }
}
