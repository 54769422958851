@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.logoMcr {
    height: 68px;

    margin-top: 20px;
}
.Top {
    display: flex;
    justify-content: space-between;

    width: 85%;
    margin: 0 auto;
    height: 128px;
}
.tab {
    display: flex;
    gap: 2rem;

    margin-top: 42px;
}

.tab h3 {
    position: relative;
    color: #f39321;
    font-family: "Roboto", sans-serif;
    font-size: 27px;
    margin-top: 23px;
    white-space: nowrap;
}

.Top h3 {
    position: relative;
    color: #c09875;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 27px;
    margin-top: 23px;
    white-space: nowrap;
    text-decoration: none !important;
}

.tab h3:hover::after {
    transform: scaleX(1);
}
.tab a {
    text-decoration: none;
    margin-left: 35px;
}

@media (max-width: 539px) {
    .logoMcr {
        height: 46px;
        margin-top: 20px;
    }

    .tab {
        display: flex;
        gap: 1rem;
        margin-top: 50px;
        text-transform: uppercase;
    }

    .tab h3 {
        position: relative;
        color: #c09875;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        margin-top: 16px;
    }

    .tab h3::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 1px;
        background-color: #c09875;
        transform: scaleX(0);
        transition: transform 0.3s ease-in-out;
    }

    .tab h3:hover::after {
        transform: scaleX(1);
    }
    .tab a {
        text-decoration: none;
        margin-left: 35px;
    }
}
