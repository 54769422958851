.Platreries {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    margin-top: 30px;
}
.page-container {
    position: relative;
}
.Platreriesmin {
    display: none;
}
.Platreries {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.image-overlaypl {
    position: relative;
}

.image-overlaytextpl {
    position: absolute;
    top: 498px;

    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: rgb(0, 0, 0);
    padding: 20px;
    max-width: 600px; 
}

.image-overlaytextpl main {
    max-width: 600px; 
}

.image-overlaytextpl h1,
.image-overlaytextpl p {
    margin-bottom: 15px;
}
/* Platreries.css */
.page-container .image-overlaytextpl h1 {
    color: #c09875; 
    font-size: 24px; 
}

.page-container .image-overlaytextpl h2 {
    color: #c09875;
    font-size: 20px;
}
.Platreries2 {
    width: 20%;
    height: auto;
    object-fit: cover;
    margin-left: 220px;
}

@media (max-width: 1054px) {
    .Platreries2 {
        width: 20%;
        height: auto;
        object-fit: cover;
        margin-left: 50%;
    }
    .Platreriesmin {
        margin-top: 34px;
        display: block;
        width: 100%;
        max-height: 50vh;
    }
    .Platreries {
        display: none;
    }

    .image-overlaytextpl h1 {
        color: #c09875;
    }
    .image-overlaytextpl ul li strong {
        color: #c09875;
    }

    .image-overlaytextpl {
        /* ... (other styles) ... */
        font-size: 20px;
        position: static; 
        transform: none;
        max-width: 100%;
        margin: 0 auto;
    }
    .page-containerpl {
        position: relative;
        width: 100%;
    }

    .image-overlaypl p {
        font-size: 20px; 
    }

    .image-overlaypl {
        text-align: center;
    }

    .image-overlaytextpl {
       
        max-height: none; 
        font-size: 20px;
        position: absolute;
        top: 850px;
        left: 38%;

        /* background-color: #000000; */

        /* background-color: #add9da;
        background: linear-gradient(to bottom, #add9da, #88c9c5); */
        transform: translate(-50%, -50%);
        text-align: left;
        color: #000000;
        z-index: 1;
        max-width: 80%;
    }
    /* Add other styles as needed */
    /* body {
        min-width: 287px;
    } */

    .image-overlaytextpl {
        font-size: 20px;
        position: static;
        transform: none;
        max-width: 100%; 
        margin: 0 auto; 
        margin-right: 61px;
        margin-left: 61px;
        margin-top: 70px;
        padding-bottom: 40px;
    }
}
